<template>
  <footer >
    <a href="https://api.whatsapp.com/send?phone=5519988497718&amp;text=Olá%20Ijr%20Mudanças%20e%20Carretos,%20gostaria%20de%20um%20orçamento."><button class="btn-whats"><img width="32" height="32" alt="Whatsapp" src="@/assets/whatsapp-32.svg"></button></a>
    <div class="grid">
      <div>
          <img width="176" height="75" src="@/assets/logo.png" alt="IJR mudanças e carreto">
      </div>
      <div>
        <ul>
          <li>
            <h3><strong>SERVIÇOS</strong></h3>
          </li>
          <li><router-link to="/mudancas-residenciais/">Mudanças
              Residenciais</router-link></li>
          <li><router-link to="/mudancas-comerciais/">Mudanças
              Comerciais</router-link></li>
          <li><router-link to="/carretos-fretes/">Fretes e Carretos </router-link>
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>
            <h3><strong>ORÇAMENTO GRATUITO</strong></h3>
          </li>
          <li>
            <p>
              A <strong>IJR MUDANÇAS E CARRETOS</strong> é uma empresa que oferece serviços de transporte em geral, <strong>Mudanças Fretes e Carretos</strong>. Através de uma combinação de experiência e inovação, nos dedicamos a <strong> oferecer a nossos clientes um serviço de qualidade</strong> e atender às suas necessidades.</p>
          </li>
          <li>
            <p>WhatsApp: 19&nbsp;<strong>98849.7718</strong></p>
          </li>
          <li>
            <a href="mailto:contato@ijrmudancasecarretos.com.br">contato@ijrmudancasecarretos.com.br</a>
          </li>
        </ul>
      </div>
    </div>
    <p>Copyright @ {{year}} - Todos os direitos reservados</p>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      year: ''
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
<style scoped lang="scss">
$c_20d366:#28a745;
$n:none;
// $abs:absolute;
// $p_100:100%;
// a{
//   padding: 1rem!important;
// }
footer {
  padding: 1rem!important;
  background: #313133;
  *{
    color: #b9b9b9 !important;
    text-align: left!important;
    overflow: hidden!important;
    margin-bottom: .5rem!important
  }
  border-top: solid .5rem #313133;
  padding-top: 2rem!important;
  strong{
    color:#e2e2e2!important
  }
}
.btn-whats {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  left:93%!important;
  @media only screen and (max-width: 600px) {
    left:75%!important
  }
  z-index: 6;
  background: $c_20d366;
  border-radius: 1000px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: $n;
  color:$n;
}
/* .btn::before {
  content: '';
  border-radius: 100px;
  min-width: calc(70px + 6px);
  min-height: calc(70px + 6px);
  border: 3px solid $c_20d366;
  position: $abs;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
} */
.btn:hover,
.btn:focus {
  color: #313133;
  transform: translateY(-6px);
}
/* .btn:hover::before,
.btn:focus::before {
  opacity: 1;
}
.btn::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: $p_100;
  border: 6px solid $c_20d366;
  position: $abs;
  z-index: -1;
  top: 50%;
  left: 50%;
 transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
} */
.btn:hover::after,
.btn:focus::after {
  animation: none;
  display: none;
}
@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0
  }
}
</style>
