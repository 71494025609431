<template>
  <div class="g2">
    <router-link to="/">
      <img class="logo" src="@/assets/logo.png" width="176" height="70"
        alt="IJR Mudanças e Fretes Logo" />
    </router-link>
    <div class="g2">
      <a href="https://api.whatsapp.com/send?phone=5519988497718&amp;text=Olá%20Ijr%20Mudanças%20e%20Carretos,%20Gostaria%20de%20solicitar%20um%20orçamento." target="_blank"><button class="b1"><img class="icon32" width="32" height="32" alt="Whatsapp" src="@/assets/whatsapp-32.svg"> WhatsApp 24 Horas</button></a>
      <a href="tel:5519988497718" target="_blank"><button class="b2"><img class="icon32" width="32" height="32" alt="Ligar agora mesmo" src='@/assets/phone-32.svg'/> Ligar agora mesmo!</button></a>
    </div>
  </div>
  <div id="nav" class="">
    <router-link to="/">IJR MUDANÇAS E CARRETOS</router-link>
    <router-link to="/mudancas-residenciais">MUDANÇAS RESIDENCIAIS</router-link>
    <router-link to="/mudancas-comerciais">MUDANÇAS COMERCIAIS</router-link>
    <router-link to="/fretes-carretos">FRETES E CARRETOS</router-link>
  </div>
</template>
<style scoped lang="scss" >
$c1:#2f3192;
.g2
.logo{
    max-width:176px!important;
    max-height:70px!important;
    width: auto;
    height: auto;
}
#nav {
  /* position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-top: 2rem!important;
  padding: 30px!important;
  background: $c1;
  z-index: 9999; */
  background: $c1;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 2px;
  row-gap: 0;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1,1fr);
    column-gap: 0;
    row-gap: 2px
  }
  a {
    font-weight: bold;
    color: #fff;
    margin-left: .7rem!important;
    padding: 10px!important;
    @media only screen and (max-width: 600px) {
    text-align: left!important;
    min-width: 100%!important;
    margin:0!important;
    }
  }
}
button.b1 {
  background: #116a26;
}
button.b2 {
  background: $c1;
}
button {
  // margin: 4px!important;
  border-radius: 50px;
  padding: .5rem 2rem!important;
  color: #fff;
  // box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25);
  cursor: pointer;
  position: relative;
  left:0!important;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    // box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, .25)
  }
}
button img{
  vertical-align: middle;
}
</style>
