<template>
  <main>
    <section><Nav /></section>
    <section><router-view/></section>
    <section><Footer /></section>
  </main>
</template>
<style lang="scss">
* {
  max-width: 100vw!important;
  text-decoration: none!important;
  box-sizing: border-box!important;
  border: 0!important;
  margin: 0!important;
  outline: 0!important;
  padding: 0!important;
  list-style: none!important;
  font-family: inherit!important;
  font-size: min(18px)!important;
  font-style: inherit!important;
  font-weight: inherit!important;
  overflow-x: hidden!important;
  a{
    min-height: 48px!important;
    width: auto!important;
    height: auto!important
  }
  text-align: center!important;
}
body{
  background: #f6f6f6;
}
p{
  text-align: justify!important;
  text-justify: inter-word!important;
  padding: 0 20px!important;
}
/* section{
  background: #ffffff;
  div{
    *{
      margin-top:.3rem!important;
    }
  }
} */
.section-router{
  padding: 1rem!important;
  max-width: 80vw!important;
  margin: 0 auto!important;
}
h4 {
  font-weight: bold;
  font-size: 17px;
  padding: 15px 25px!important;
}
h4 {
  font-size: 1.4em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1em 0!important;
  font-weight: 500;
  padding: 0 20px!important;
}
h4 {
  display: block;
  font-weight: bold;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center!important;
}
.g2{
  margin:1rem 0!important;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  row-gap: 0;
  @media only screen and (max-width: 600px) {
    a{
     width: auto!important;
    }
    grid-template-columns: repeat(1,1fr);
    row-gap: 1px
  }
}
@media only screen and (max-width: 600px) {

  .grid {
    img{
    background-size: contain!important
  }
    max-width: 99vw;
    grid-template-columns: repeat(1, 1fr)
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Nav,
    Footer
  }
}
</script>
