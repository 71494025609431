import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mudancas-comerciais',
    name: 'Mudancas-comerciais',
    component: () => import('../views/mudancas-comerciais.vue')
  },
  {
    path: '/mudancas-residenciais',
    name: 'Mudancas-residenciais',
    component: () => import('../views/mudancas-residenciais.vue')
  },
  {
    path: '/fretes-carretos',
    name: 'Fertes-Carretos',
    component: () => import('../views/fretes-carretos.vue')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})
export default router
