<template É só ligarv-on:scrol="handleScroll()">
  <section class="bx">
    <div class="grid">
      <div>
        <img src="@/assets/IJR_Mudancas_Fretes_f1.webp" width="300" height="300" srcset="
                                  @/assets/IJR_Mudancas_Fretes_f1.webp 500w,
                                  @/assets/IJR_Mudancas_Fretes_f1-300x300.webp 300w,
                                  @/assets/IJR_Mudancas_Fretes_f1-150x150.webp 150w
                                " sizes="(max-width:500px) 100vw, 500px" alt="" />
      </div>
      <div>
        <img src="@/assets/IJR_Mudancas_Fretes_f2.webp" width="300" height="300" srcset="
                                  @/assets/IJR_Mudancas_Fretes_f2.webp 500w,
                                  @/assets/IJR_Mudancas_Fretes_f2-300x300.webp 300w,
                                  @/assets/IJR_Mudancas_Fretes_f2-150x150.webp 150w
                                " sizes="(max-width:500px) 100vw, 500px" alt="" />
      </div>
      <div>
        <img src="@/assets/IJR_Mudancas_Fretes_f3.webp" width="300" height="300" srcset="
                                  @/assets/IJR_Mudancas_Fretes_f3.webp 500w,
                                  @/assets/IJR_Mudancas_Fretes_f3-300x300.webp 300w,
                                  @/assets/IJR_Mudancas_Fretes_f3-150x150.webp 150w
                                " sizes="(max-width:500px) 100vw, 500px" alt="" />
      </div>
    </div>
    <div class="grid">
      <div>
        <router-link to="/mudancas-comerciais/">
          <div>
            <div class="circle-icon">
              <img width="32" height="32" src="@/assets/truck.svg" alt="">
            </div>
            <h4>MUDANÇA COMERCIAL</h4>
            <p>
              Realizamos mudanças comerciais como:mudança de bens,
              móveis, equipamentos de escritórios, salas comerciais,
              fábricas e indústrias.
            </p>
            <p><strong>Mais informações</strong></p>
          </div>
        </router-link>
      </div>
      <div>
        <router-link to="/mudancas-residenciais/">
          <div class="circle-icon">
            <img width="32" height="32" src="@/assets/house.svg" alt="">
          </div>
          <h4>MUDANÇA RESIDENCIAL</h4>
          <p>
            Fazemos mudanças residenciais para região de Campinas ou
            longas distâncias (interestaduais) com ótimo custo x
            benefício.
          </p>
          <p><strong>Mais informações</strong></p>
        </router-link>
      </div>
      <div>
        <router-link to="/fretes-carretos/">
          <div class="circle-icon">
            <img width="32" height="32" src="@/assets/case.svg" alt="">
          </div>
          <h4 class="title">FRETES E CARRETOS</h4>
          <p>
            Pequenos transportes em Campinas? Ligue agora mesmo para
            IJR TRANSPORTES! Temos Pick-Ups, Vans e Caminhões Baú.
          </p>
          <p><strong>Mais informações</strong></p>
        </router-link>
      </div>
    </div>
    <div class="g2">
      <div>
        <p>
          A IJR MUDANÇAS E CARRETOS, a empresa atua no segmento de Mudanças e Fretes, com o objetivo de oferecer os
          melhores serviços a seus clientes.
          Com mais de 16 anos de experiência, nossa equipe formada por profissionais especializados, busca sempre o máximo
          resultado na prestação dos serviços com
          segurança, eficiência e rapidez. Tudo
          bem planejado e executado por profissionais.
        </p>
        <h4 class="title">É só ligar e agendar:19 98849.7718</h4>
      </div>
      <div>
        <video id="v02" controls loop width="510" height="316">
          <source style="width: 100%; height: 100%;" src="../assets/vd.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </section>
  <Contato />
</template>
<script>
import Contato from '@/components/Contato.vue'
export default {
  name: 'HelloWorld',
  components: {
    Contato
  },
  props: {
    msg: String
  },
  methods: {
    play () {
      setTimeout(function () {
        var audio = document.getElementById('myvideo')
        audio.play()
      }, 3000)
    },
    handleScroll () {
      var v01 = document.getElementById('v01')
      if (v01.play() !== true) {
        v01.play()
      }
      var v02 = document.getElementById('v02')
      if (v02.play() !== true) {
        v02.play()
      }
    }
  }
}
</script>
<style scoped  lang="scss">
$c1: #2f3192;
.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $c1 !important;
  width: 65px !important;
  height: 65px !important;
  border-radius: 100%;
  margin:0 auto !important;
  img {
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    margin: 0 !important
  }
}
.title {
  font-size: 25px;
}
.grid, .g2{
  padding: 5rem!important;
  div{
    background: red;
  }
}
.bx {
  div{
    background: #fff;
  }
  * {
    color: #3b3b3b !important;
    margin-bottom: 1rem !important
  }
}
video {
  max-width: 90vw !important;
  width: auto !important;
  height: auto !important;
  object-fit: fill;
  @media only screen and (max-width: 600px) {
    min-width: 100% !important
  }
}
</style>
