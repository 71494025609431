<template>
  <section class="section-router g2">
    <form @submit.prevent="email($event)" method="post" action="email.php">
      <h3>
        Peça um Orçamento para MUDANÇAS / CARRETOS / FRETES
      </h3>
      <div>
        <span>
          <input id="name" type="" value="" placeholder="Seu nome" />
        </span>
        <span>
          <input id="celular" type="tel" value="" placeholder="(DDD) + fixo ou celular*" />
        </span>
        <span>
          <input id="email" type="email" value="" placeholder="O seu e-mail" />
        </span>
        <span>
          <textarea id="msg" placeholder="Mensagem*"></textarea>
        </span>
        <input type="hidden" name="instance_hash" value="f12c72526c09940424984103b851861c" />
        <input type="hidden" id="_wpnonce" name="_wpnonce" value="b83f8b12d9" /><input type="hidden"
          name="_wp_http_referer" value="/" />
        <input v-on:submit="email" type="submit" value="ENVIAR" />
      </div>
    </form>
    <div>
      <h3>ORÇAMENTO GRATUITO</h3>
      <div>
        <p>&nbsp;</p>
        <p>
          <img loading="lazy" src="@/assets/ijr-caminhao1.jpg" alt="" width="300" height="300" srcset="
                                @/assets/ijr-caminhao1.jpg         500w,
                                @/assets/ijr-caminhao1-300x205.jpg 300w
                              " sizes="(max-width:500px) 100vw, 500px" />
        </p>
        <p>
          A <strong>IJR MUDANÇAS E CARRETOS</strong> está sediada em Campinas/SP, atuando imediatamente em toda a região e
          disponibilizando serviços em todo o Brasil. Nossa eficiente equipe com ampla experiência em transporte nos ajuda
          a garantir que seu embarque chegue com segurança, quando e onde ele deve chegar. Somos uma empresa de mudança
          comercial e residencial com uma excelente reputação, oferecendo tarifas muito competitivas. Se você quiser saber
          mais sobre nossos serviços, ligue para nós agora.
        </p>
        <p>WhatsApp:19 <strong>98849.7718</strong></p>
        <p>
          <a href="mailto:contato@ijrmudancasecarretos.com.br">contato@ijrmudancasecarretos.com.br</a>
        </p>
      </div>
    </div>
  </section>
</template>
<script>

import axios from 'axios'
export default {
  name: 'main',
  data: function () {
    return {
      authorization: 'N',
      year: ''
    }
  },
  methods: {
    email () {
      alert('inicio')
      axios.post('/api/email.php', { name: document.getElementById('name').value, email: document.getElementById('email').value, celular: document.getElementById('celular').value, msg: document.getElementById('msg').value }, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((res) => {
        alert('Ok Feito' + res)
      })
    }
  }
}
</script>
<style scoped lang="scss">
input,
textarea {
  display: block;
  width: 100%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0px;
  border: 1px solid #c0c0c0 !important;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

img {
  min-width: 100% !important;
  width: auto;
  height: auto;
}

.contato {
  padding: 1rem !important;

  * {
    margin-bottom: 1rem !important;
    color: #333333 !important
  }
}
</style>
