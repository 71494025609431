<template>
  <section>
    <Banner/>
    <section class="section-router">
    <HomeView msg="Welcome to Your Vue.js App"/>
  </section>
  </section>
</template>
<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'
import HomeView from '@/components/Home.vue'
export default {
  name: 'Home',
  components: {
    Banner,
    HomeView
  }
}
</script>
