<template>
  <section>
      <div class="img-banner " v-for="(slide, index) in slides" v-bind:key="slide.sublineSecondLine"
        v-bind:style="{ 'display': '' + slide.display + '', 'background-image': 'url(' + slide.Img + ')' }">
        <div class="example">
          <div class="n2-style font-hover">{{ slide.sublineSecondLine }}</div>
          <div :name="index" class="btn-banner">PEÇA UM ORÇAMENTO</div>
        </div>
      </div>
  </section>
</template>
<style scoped lang="scss">
section{
  margin-bottom: 3rem!important;
}
.example {
  border-style: solid;
  background: none;
  display: -moz-box; /* Mozilla */
  display: -webkit-box; /* WebKit */
  height: 300px;
  /* Children should be oriented vertically */
  -moz-box-orient: vertical; /* Mozilla */
  -webkit-box-orient: vertical; /* WebKit */
  /* Align children to the horizontal center of this box */
  -moz-box-align: center; /* Mozilla */
  -webkit-box-align: center; /* WebKit */
  /* Pack children to the bottom of this box */
  -moz-box-pack: end; /* Mozilla */
  -webkit-box-pack: end; /* WebKit */
}
/*  */
.b {
  display: grid;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.c {
  position: absolute;
  bottom: 0;
  right: 10%;
  left: 10%;
  display: none;
}
.banner {
  margin-top: .1rem!important;
  width: 100%;
  height: 400px;
  *{
    margin-bottom: 1rem!important
  }
}
.img-banner {
  max-width: 100%!important;
  height:auto!important;
 /*  background-size: 100% 100%;
  @media only screen and (max-width: 600px) {
    background-size:auto
  }
  background-repeat: no-repeat; */
}
.div-banner {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-banner {
  position: relative;
  margin-top: 4px!important;
  background: #ef0000;
  opacity: 1;
  padding: 10px 30px 10px 30px!important;
  box-shadow: none;
  border-width: 0px;
  border-style: solid;
  border-color: #000000;
  border-color: RGBA(0, 0, 0, 1);
  border-radius: 99px;
  width: 20%;
  @media only screen and (max-width: 600px) {
    width: 50%
  }
}
.slideteste {
  transition: 'transform 0.4s ease';
  transform: 'translateX(0%)';
  z-index: '1'
}
.n2-style {
  background: #2f3192;
  opacity: 0.7;
  box-shadow: none;
  border-width: 0px;
  border-style: solid;
  border-color: #000000;
  border-color: RGBA(0, 0, 0, 1);
  border-radius: 0px;
  width: 90%;
}
.font-hover {
  font-family: 'Roboto', 'Arial';
  color: #ffffff;
  font-size: 225%;
  text-shadow: none;
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
}
</style>
<script>
export default {
  name: 'Banner',
  data () {
    return {
      currentSlide: 0,
      isPreviousSlide: false,
      isFirstLoad: true,
      slides: [
        {
          sublineSecondLine: 'CARRETOS, FRETES E MUDANÇAS COMERCIAIS',
          Img: require('@/assets/mudancas-fretes-residencial.webp'),
          display: 'none'
        },
        {
          sublineSecondLine: 'MUDANÇAS RESIDENCIAIS CAMPINAS E REGIÃO',
          Img: require('@/assets/mudancas-fretes-comercial.webp'),
          display: 'block'
        }
      ]
    }
  },
  mounted () {
    this.nextSlide()
  },
  methods: {
    nextSlide () {
      for (const x in this.slides) {
        if (this.slides[x].display === 'none') {
          this.slides[x].display = 'block'
        } else {
          this.slides[x].display = 'none'
        }
      }
      setTimeout(this.nextSlide, 7000)
    }
  }
}
</script>
